'use client';

import { makeVar, useReactiveVar } from '@apollo/client';
import { GetV3PoolsQueryVariables, GqlPoolFilterCategory, GqlPoolOrderBy, GqlPoolOrderDirection, useGetPoolFiltersQuery, useGetV3PoolsQuery } from '~/apollo/generated/graphql-codegen-generated';
import { eq } from 'lodash';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ChainId } from '@baseswapfi/sdk-core';
import { useChainId } from 'wagmi';
import { isSameAddress } from '~/lib/util/address';
import { isSupportedChain } from '~/lib/util/chains';
interface PoolsQueryVariables extends GetV3PoolsQueryVariables {
  first: number;
  skip: number;
}
export const DEFAULT_POOL_LIST_QUERY_VARS: PoolsQueryVariables = {
  chainId: ChainId.BASE,
  // Default to something for Next static props build data
  first: 28,
  skip: 0,
  orderBy: 'totalLiquidity',
  orderDirection: 'desc',
  textSearch: null
};
const poolListStateVar = makeVar<PoolsQueryVariables>(DEFAULT_POOL_LIST_QUERY_VARS);
const showMyInvestmentsVar = makeVar(false);
const showFiltersVar = makeVar(false);
const searchTextVar = makeVar('');
export function _usePoolList() {
  const chainId = useChainId();

  // Used to allow configurable liquidity threshold in implementation at table level.
  const [minLiquidity, setMinLiquidity] = useState(500);
  const state = useReactiveVar(poolListStateVar);
  const showMyInvestments = useReactiveVar(showMyInvestmentsVar);
  const searchText = useReactiveVar(searchTextVar);
  const {
    data,
    loading,
    error,
    networkStatus,
    refetch: refetchPools,
    stopPolling
  } = useGetV3PoolsQuery({
    notifyOnNetworkStatusChange: true,
    pollInterval: 30000,
    variables: {
      ...state,
      chainId
    }
  });
  const {
    data: poolFilters
  } = useGetPoolFiltersQuery({
    fetchPolicy: 'cache-first'
  });
  const pools = data?.getV3Pools || [];
  const poolsWithLiquidity = pools.filter(pool => pool.dynamicData?.totalLiquidity > minLiquidity);
  async function refetch(newState: PoolsQueryVariables) {
    poolListStateVar(newState);
    return refetchPools(newState);
  }
  useEffect(() => {
    if (isSupportedChain(chainId)) {
      poolListStateVar({
        ...poolListStateVar(),
        chainId
      });
    }
  }, [chainId]);
  useEffect(() => {
    if (isSupportedChain(chainId)) {
      const currentState = poolListStateVar();
      if (currentState.chainId !== chainId) {
        poolListStateVar({
          ...currentState,
          chainId: chainId
        });
      }
    }
  }, [chainId]);
  if (error) console.log(error);
  async function changeSort(orderBy: GqlPoolOrderBy) {
    const state = poolListStateVar();
    if (state.orderBy === orderBy) {
      await refetch({
        ...state,
        orderDirection: state.orderDirection === 'asc' ? null : state.orderDirection === 'desc' ? 'asc' : 'desc'
      });
    } else {
      await refetch({
        ...state,
        orderBy: orderBy,
        orderDirection: 'desc'
      });
    }
  }
  async function setSort(orderBy: GqlPoolOrderBy, orderDirection: GqlPoolOrderDirection) {
    const state = poolListStateVar();
    await refetch({
      ...state,
      orderBy: orderBy,
      orderDirection
    });
  }
  async function setPageSize(pageSize: number) {
    await refetch({
      ...poolListStateVar(),
      first: pageSize,
      skip: 0
    });
  }
  function setShowMyInvestments(show: boolean) {
    showMyInvestmentsVar(show);
  }
  function setSearchText(text: string) {
    searchTextVar(text);
  }
  function toggleFilterVisibility() {
    showFiltersVar(!showFiltersVar());
  }
  async function setPoolIds(poolIds: string[], categoryIn: GqlPoolFilterCategory[] = null) {
    const state = poolListStateVar();
    if (!eq(poolIds, state.where?.idIn)) {
      await refetch({
        ...state,
        where: {
          ...state.where,
          idIn: poolIds,
          categoryIn,
          categoryNotIn: null
        },
        first: 100
      });
    }
  }
  async function clearPoolIds() {
    const state = poolListStateVar();
    await refetch({
      ...state,
      where: {
        ...state.where,
        idIn: undefined
      }
    });
  }
  function getPools(addresses: string[]) {
    addresses = addresses.map(a => a.toLowerCase());
    return pools.filter(p => addresses.includes(p.address));
  }
  function getPool(address: string) {
    return pools.find(p => isSameAddress(p.address, address));
  }
  return {
    state,
    pools,
    poolsWithLiquidity,
    setMinLiquidity,
    count: data?.count,
    loading,
    error,
    networkStatus,
    refetchV3Pools: refetch,
    changeSort,
    setSort,
    setPageSize,
    showMyInvestments,
    setShowMyInvestments,
    filters: poolFilters?.filters || [],
    toggleFilterVisibility,
    showFilters: useReactiveVar(showFiltersVar),
    setPoolIds,
    clearPoolIds,
    searchText,
    setSearchText,
    getPools,
    getPool,
    stopPolling
  };
}
export const V3PoolListContext = createContext<ReturnType<typeof _usePoolList> | null>(null);
export function V3PoolListProvider(props: {
  children: ReactNode;
}) {
  const value = _usePoolList();
  return <V3PoolListContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="V3PoolListProvider" data-sentry-source-file="useV3PoolList.tsx">{props.children}</V3PoolListContext.Provider>;
}
export function useV3PoolList() {
  return useContext(V3PoolListContext) as ReturnType<typeof _usePoolList>;
}