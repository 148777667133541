'use client';

import { Flex, Grid, Text } from '@chakra-ui/react';
import { ExternalLink } from 'react-feather';
import Link from 'next/link';
import useNetworkConfig from '~/lib/global/useNetworkConfig';
export default function Documentation() {
  const networkConfig = useNetworkConfig();
  // split out anything using network config to separate file and preserve server boundary
  return <Flex w="100%" direction="column" align="flex-end" data-sentry-element="Flex" data-sentry-component="Documentation" data-sentry-source-file="Documentation.tsx">
      <Flex direction="column" p="4" borderRadius="18px" w={{
      base: '100%',
      lg: '70%'
    }} border="1px solid" borderColor="darkborder" data-sentry-element="Flex" data-sentry-source-file="Documentation.tsx">
        <Flex w="100%" justify="flex-start" align="center" data-sentry-element="Flex" data-sentry-source-file="Documentation.tsx">
          <Text fontWeight="500" fontSize="24px" mr="4" color="highlight" data-sentry-element="Text" data-sentry-source-file="Documentation.tsx">
            {' '}
            Want to know more?{' '}
          </Text>
          <Link href={networkConfig.socialLinks.documents} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="Documentation.tsx">
            <ExternalLink size="24" data-sentry-element="ExternalLink" data-sentry-source-file="Documentation.tsx" />
          </Link>
        </Flex>
        <Flex w="100%" justify="flex-start" align="center" mt="2rem" data-sentry-element="Flex" data-sentry-source-file="Documentation.tsx">
          <Text fontWeight="400" fontSize="18px" data-sentry-element="Text" data-sentry-source-file="Documentation.tsx">
            Check out our documentation&nbsp;
          </Text>
          <Link href={networkConfig.socialLinks.documents} target="_blank" rel="noopener noreferrer" data-sentry-element="Link" data-sentry-source-file="Documentation.tsx">
            <Text fontWeight="400" fontSize="18px" color="text.100" textDecoration="underline" textDecorationColor="highlight" data-sentry-element="Text" data-sentry-source-file="Documentation.tsx">
              here.
            </Text>
          </Link>
        </Flex>
      </Flex>
      <Flex direction="column" p="4" mt="2rem" borderRadius="18px" w={{
      base: '100%',
      lg: '70%'
    }} border="1px solid" borderColor="darkborder" data-sentry-element="Flex" data-sentry-source-file="Documentation.tsx">
        <Flex w="100%" justify="flex-start" align="center" data-sentry-element="Flex" data-sentry-source-file="Documentation.tsx">
          <Text fontWeight="500" fontSize="24px" mr="4" color="highlight" data-sentry-element="Text" data-sentry-source-file="Documentation.tsx">
            {' '}
            Or just get started.{' '}
          </Text>
        </Flex>
        <Link href="/swap" data-sentry-element="Link" data-sentry-source-file="Documentation.tsx">
          <Flex w="100%" justify="flex-start" align="center" mt="2rem" data-sentry-element="Flex" data-sentry-source-file="Documentation.tsx">
            <Text fontWeight="400" fontSize="18px" mr="4" color="text.100" textDecoration="underline" textDecorationColor="highlight" data-sentry-element="Text" data-sentry-source-file="Documentation.tsx">
              {' '}
              Swap Now.
            </Text>
          </Flex>
        </Link>
      </Flex>
    </Flex>;
}