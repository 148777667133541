'use client';

import { Box, Flex, Text } from '@chakra-ui/react';
import Link from 'next/link';
import numeral from 'numeral';
import { memo } from 'react';
import { TokenAvatarSetInList } from '~/app/components/token/TokenAvatarSetInList';
import PoolSymbol from '~/app/pools/components/PoolSymbol';
const FeaturedPoolContent = ({
  pool
}) => {
  const MemoizedTokenAvatarSetInList = memo(TokenAvatarSetInList);
  const formatApr = apr => {
    if (apr < 0.0000001) {
      return '0.0%';
    }
    return numeral(apr).format('0.0%');
  };
  const formatVolume = value => {
    if (value == null || isNaN(value)) {
      return '0.0a';
    }
    return numeral(value).format('0.0a');
  };
  const displayVolume = formatVolume(pool.dynamicData?.volume24h);
  const totalAPR = pool.aprItems ? pool.aprItems.reduce((acc, curr) => acc + curr.apr, 0) : 0;
  const poolAPR = formatApr(totalAPR);
  return <Flex direction="column" w="100%" justify="center" align="center" p={1} h="100%" data-sentry-element="Flex" data-sentry-component="FeaturedPoolContent" data-sentry-source-file="Content.tsx">
      <Flex align="center" justify="space-between" w="100%" data-sentry-element="Flex" data-sentry-source-file="Content.tsx">
        <MemoizedTokenAvatarSetInList imageSize={65} width={120} showPopover={false} tokens={[pool.token0, pool.token1]} data-sentry-element="MemoizedTokenAvatarSetInList" data-sentry-source-file="Content.tsx" />
        <PoolSymbol pool={pool} data-sentry-element="PoolSymbol" data-sentry-source-file="Content.tsx" />
      </Flex>

      <Flex w="100%" mt="1rem" justify="space-evenly" flex="1" h="100%" p="2" data-sentry-element="Flex" data-sentry-source-file="Content.tsx">
        <Flex direction="column" flex="1" justify="center" align="flex-start" data-sentry-element="Flex" data-sentry-source-file="Content.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="Content.tsx">24h VOL</Text>
          <Text fontSize="40px" color="highlight" data-sentry-element="Text" data-sentry-source-file="Content.tsx">
            ${displayVolume}
          </Text>
        </Flex>
        <Flex direction="column" flex="1" justify="center" align="flex-end" data-sentry-element="Flex" data-sentry-source-file="Content.tsx">
          <Text data-sentry-element="Text" data-sentry-source-file="Content.tsx">APR</Text>
          <Text fontSize="40px" color="highlight" data-sentry-element="Text" data-sentry-source-file="Content.tsx">
            {poolAPR}
          </Text>
        </Flex>
      </Flex>

      <Box mt={4} textAlign="center" data-sentry-element="Box" data-sentry-source-file="Content.tsx">
        <Text fontSize="18px" color="text.100" textDecoration="underline" textDecorationColor="highlight" cursor="pointer" _hover={{
        color: 'highlight',
        textDecorationColor: '#fff'
      }} data-sentry-element="Text" data-sentry-source-file="Content.tsx">
          <Link target="_blank" href={`/pool/v3/${pool.id}`} data-sentry-element="Link" data-sentry-source-file="Content.tsx">
            Explore Pool
          </Link>
        </Text>
      </Box>
    </Flex>;
};
export default FeaturedPoolContent;